/* storybook-check-ignore */
import React, { useMemo } from 'react';

import { Box, Flex, Link, Text } from '@opendoor/bricks/core';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import ArrowLeft from '@opendoor/bricks/core/Icon/SpritesheetIcons/ArrowLeft';
import ArrowRight from '@opendoor/bricks/core/Icon/SpritesheetIcons/ArrowRight';
import ChevronRight16 from '@opendoor/bricks/core/Icon/SpritesheetIcons/ChevronRight16';
import Close16 from '@opendoor/bricks/core/Icon/SpritesheetIcons/Close16';
import NewTab from '@opendoor/bricks/core/Icon/SpritesheetIcons/NewTab';
import { useRouter } from 'next/router';

import { Footer } from 'components/exclusives/landing/components/Footer';
import { HeroTemplate } from 'components/exclusives/landing/components/hero/templates/HeroTemplate';
import { CalloutSectionTemplate } from 'components/exclusives/landing/components/section/templates/CalloutSectionTemplate';
import { GroupSectionTemplate } from 'components/exclusives/landing/components/section/templates/GroupSectionTemplate';
import { TextSectionTemplate } from 'components/exclusives/landing/components/section/templates/TextSectionTemplate';
import { ResponsivePicture } from 'components/exclusives/ResponsivePicture';

import { ListingWithComputedProperties } from 'declarations/exclusives/listing';

import { getActiveListings } from 'helpers/exclusives/listing';

import ErrorOnListingsFetchBanner from '../ErrorOnListingsFetchBanner';
import GallerySlider from '../gallery/GallerySlider';
import { BuyerHeroAnimation } from './components/BuyerHeroAnimation';
import { EarlyAccessSectionAnimation } from './components/EarlyAccessSectionAnimation';
import { OffMarketPricesSectionAnimation } from './components/OffMarketPricesSectionAnimation';
import { CarouselSectionTemplate } from './components/section/templates/CarouselSectionTemplate/CarouselSectionTemplate';

type BuyerLandingPageV2Props = {
  errorOnListingsFetch: boolean;
  homes: ListingWithComputedProperties[];
};

export const BuyerLandingPageV2: React.FC<BuyerLandingPageV2Props> = ({
  errorOnListingsFetch,
  homes,
}) => {
  const router = useRouter();

  const activeListings = useMemo(() => getActiveListings(homes), [homes]);

  return (
    <Box>
      <SelectiveSpritesheet icons={[ArrowLeft, ArrowRight, ChevronRight16, Close16, NewTab]} />
      <HeroTemplate
        actions={
          <Flex
            alignItems={[null, null, 'center']}
            flexDirection={['column', null, 'row']}
            gap={32}
            width="100%"
          >
            <Link
              analyticsName="cosmos-exclusives-home-hero-see-all-homes"
              aria-label="View all homes"
              fontSize="20px"
              href="/exclusives/gallery"
              lineHeight="s2"
              maxW={[238, 238, 240, 270]}
              minH={['56px', null, '68px']}
              minW="210px"
              padding={[null, null, '20px 24px']}
              size="md"
              variant="primary-button"
            >
              View {activeListings.length} homes
            </Link>
            <Text color="warmgrey800" size="110">
              Available in{' '}
              <Link
                color="warmgrey800"
                analyticsName="cosmos-exclusives-home-hero-dallas-gallery"
                aria-label="View all Dallas homes"
                href="/exclusives/gallery/dallas"
              >
                Dallas–Fort Worth,{' '}
              </Link>
              <Link
                color="warmgrey800"
                analyticsName="cosmos-exclusives-home-hero-raleigh-gallery"
                aria-label="View all Raleigh homes"
                href="/exclusives/gallery/Raleigh"
              >
                Raleigh-Durham
              </Link>
              , and{' '}
              <Link
                color="warmgrey800"
                analyticsName="cosmos-exclusives-home-hero-charlotte-gallery"
                aria-label="View all Charlotte homes"
                href="/exclusives/gallery/charlotte"
              >
                Charlotte
              </Link>
            </Text>
          </Flex>
        }
        description="Exclusive homes only listed on Opendoor"
        image={<BuyerHeroAnimation />}
        title={
          router.query.h1 || 'Be first to find your next home before it is listed anywhere else'
        }
      />

      <GroupSectionTemplate
        isSubsection
        label="How it works"
        title="Get an edge in your home buying"
      >
        <TextSectionTemplate
          description="Choose a time to explore at your own pace, we’ll open the door for you. Touring with an agent? They can book directly."
          image={
            <ResponsivePicture
              alt=""
              src="https://imgdrop.imgix.net/2025-02-1738875153346-13607.png?preset=square-2048"
              maxWidth={['100%', null, 353]}
            />
          }
          isSubsection
          label="Early access tours"
          paddingBottom={[0, 0, 0, 0]}
          title="Reserve Your Tour"
          textContainerProps={{
            paddingBottom: [null, null, null, 96],
          }}
        />

        <TextSectionTemplate
          bgColor="warmgrey100"
          description="Get early access to homes you can't find anywhere else"
          image={<EarlyAccessSectionAnimation />}
          isSubsection
          label="Early access"
          paddingBottom={[0, 0, 0, 0]}
          title="Be among the first to know"
          textContainerProps={{
            paddingBottom: [null, null, null, 96],
          }}
        />

        <TextSectionTemplate
          description="Buy directly from sellers looking for an easier way to sell"
          image={<OffMarketPricesSectionAnimation />}
          isSubsection
          label="Early access prices"
          paddingBottom={[0, 0, 0, 0]}
          title="Find your next home for a price you’ll love"
          textContainerProps={{
            paddingBottom: [null, null, null, 96],
          }}
        />
      </GroupSectionTemplate>

      <GroupSectionTemplate
        isSubsection
        title={<Box marginBottom={['32px', null, '48px']}>Homes on Opendoor Exclusives</Box>}
        backgroundColor="warmgrey100"
      >
        {!errorOnListingsFetch ? (
          <GallerySlider
            listings={activeListings}
            pb={[0, null, '68px']}
            sliderSettings={{
              lazyLoad: 'anticipated',
              initialSlide: 3,
            }}
          />
        ) : (
          <Flex maxWidth="max(900px, 60vw)" mx="auto" py="2rem" px={['1.5rem', '2rem']}>
            <ErrorOnListingsFetchBanner bgColor="neutrals10" />
          </Flex>
        )}
      </GroupSectionTemplate>

      <CarouselSectionTemplate
        featuredIn={
          <>
            <ResponsivePicture
              alt="The New York Times logo"
              src="https://images.opendoor.com/source/s3/imgdrop-production/2023-08-1691015064528-20871.png?preset=square-2048"
              width={188}
            />
            <ResponsivePicture
              alt="Forbes logo"
              src="https://images.opendoor.com/source/s3/imgdrop-production/2023-08-1691015064538-03792.png?preset=square-2048"
              width={92}
            />
            <ResponsivePicture
              alt="Wallstreet Journel logo"
              src="https://images.opendoor.com/source/s3/imgdrop-production/2023-08-1691015064535-38031.png?preset=square-2048"
              width={46}
            />
            <ResponsivePicture
              alt="Fortune logo"
              src="https://images.opendoor.com/source/s3/imgdrop-production/2023-08-1691015064533-96506.png?preset=square-2048"
              width={98}
            />
          </>
        }
        isSubsection
        quotes={[
          {
            author: {
              name: 'Colton Katzen',
              role: 'Bought with Opendoor Exclusives',
            },
            quote:
              'We decided to try Opendoor Exclusives and it could not have been a smoother process from beginning to end.',
          },
          {
            author: {
              name: 'Joe Godina',
              role: 'Bought with Opendoor Exclusives',
            },
            quote: (
              <>
                We've heard of the term picture-perfect, that's how I'd describe the Opendoor
                Exclusive experience.
              </>
            ),
          },
          {
            author: {
              name: 'Kate R',
              role: 'Bought with Opendoor Exclusives',
            },
            quote:
              'We went from not being in the market to buy one week to being under contract on a home we loved the next!',
          },
        ]}
        title="Praise from our buyers"
      />

      <CalloutSectionTemplate
        actions={
          <Flex
            alignItems={[null, null, 'center']}
            flexDirection={['column', null, 'row']}
            gap={32}
            width="100%"
          >
            <Link
              analyticsName="cosmos-exclusives-home-hero-see-all-homes"
              aria-label="View all homes"
              fontSize="20px"
              href="/exclusives/gallery"
              lineHeight="120"
              maxW={[238, 238, 240, 270]}
              minH={['56px', null, '68px']}
              minW="210px"
              padding={[null, null, '20px 24px']}
              size="md"
              variant="primary-button"
            >
              View {activeListings.length} homes
            </Link>
            <Flex
              alignItems={[null, null, 'center']}
              flexDirection={['column', null, 'row']}
              gap={32}
              width="100%"
            >
              <Text color="warmgrey800" size="110">
                Available in{' '}
                <Link
                  color="warmgrey800"
                  analyticsName="cosmos-exclusives-home-hero-dallas-gallery"
                  aria-label="View all Dallas homes"
                  href="/exclusives/gallery/dallas"
                >
                  Dallas–Fort Worth
                </Link>
                , <br />
                <Link
                  color="warmgrey800"
                  analyticsName="cosmos-exclusives-home-hero-raleigh-gallery"
                  aria-label="View all Raleigh homes"
                  href="/exclusives/gallery/Raleigh"
                >
                  Raleigh-Durham
                </Link>
                , <br /> and{' '}
                <Link
                  color="warmgrey800"
                  analyticsName="cosmos-exclusives-home-hero-charlotte-gallery"
                  aria-label="View all Charlotte homes"
                  href="/exclusives/gallery/charlotte"
                >
                  Charlotte
                </Link>
              </Text>
            </Flex>
          </Flex>
        }
        image={
          <ResponsivePicture
            alt="People sitting at a dining table"
            src="https://images.opendoor.com/source/s3/imgdrop-production/2023-08-1691000405670-03595.png?preset=square-2048"
          />
        }
        isSubsection
        title="Be the first to find your next home. Only on Opendoor"
      />
      <Footer
        cta={
          <Link
            analyticsName="cosmos-exclusives-home-hero-see-all-homes"
            aria-label="View all homes"
            fontSize="16px"
            href="/exclusives/gallery"
            lineHeight="120"
            maxW="200px"
            minW="180px"
            size="md"
            variant="secondary-button"
          >
            View {activeListings.length} homes
          </Link>
        }
      />
    </Box>
  );
};
